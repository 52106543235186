import Swiper from 'swiper/swiper-bundle.min'

function initOrgCallback() {
  $('.js-orgCallback').each((index, elem) => {
    const elemParent = $(elem).parents('.soc-slider')
    $(elem).addClass(`js-orgCallback-${index}`)

    elemParent.find('.js-orgCallbackBtnNext').addClass(`js-orgCallbackBtnNext-${index}`)
    elemParent.find('.js-orgCallbackBtnPrev').addClass(`js-orgCallbackBtnPrev-${index}`)

    const sliderOrgCallback = new Swiper(`.js-orgCallback-${index}`, {
      slidesPerView: 1,
      spaceBetween: 16,
      navigation: {
        nextEl: `.js-orgCallbackBtnNext-${index}`,
        prevEl: `.js-orgCallbackBtnPrev-${index}`,
      },
    })
  })
}

$(document).ready(initOrgCallback)
