import Swiper, { Navigation, Autoplay, Pagination } from 'swiper'

Swiper.use([Navigation, Autoplay, Pagination])

if (document.querySelector('.news-detail-slider')) {
  const changeDescription = (value) => {
    document.querySelector('.js-imageDescription').textContent = value
  }

  new Swiper('.js-newsDetailSlider', {
    slidesPerView: 1,
    centeredSlides: false,
    spaceBetween: 8,
    grabCursor: true,
    init: true,
    pagination: {
      el: '.js-newsDetailSlider-pagination',
      type: 'fraction',
    },
    navigation: {
      nextEl: '.js-newsDetailSliderNext',
      prevEl: '.js-newsDetailSliderPrev',
    },
    on: {
      init(swiper) {
        changeDescription(swiper.slides[0].dataset.alt)
      },
      slideChange(swiper) {
        changeDescription(swiper.slides[swiper.activeIndex].dataset.alt)
      },
    },
  })
}

