import { validateExtensions, validateFileSize, formatBytes } from './helpers'

let maxSize = 1000000000000000
let extensions = ['.docx', '.pdf', '.jpg', '.png', '.jpeg']
// const fileUploadList = $('.js-fileUploadList')
const inputHasProgress = $('.js-inputHasProgress')

if (inputHasProgress.length > 0) {
  extensions = ['.docx', '.pdf', '.jpg', '.png', '.jpeg', '.jpg', '.mp4', '.zip', '.rar', '.xlsx', '.xls']
  maxSize = 52428800
}

function initUpload() {
  $('.js-fileUploadInput').each((index, elem) => {
    let isRequired = $(elem).attr('required');

    if ($(elem).is('[data-size]') === false) {
      $(elem).attr('data-size', maxSize)
    }

    if ($(elem).is('[data-extensions]') === false) {
      $(elem).attr('data-extensions', extensions)
    }

    $(elem).before(`<input class='js-fileSize' type='hidden' value='${$(elem).data('size')}'/>`)
    // $(elem).before(`<input class='js-fileExtensions' type='hidden' value='${$(elem).data('extensions')}'/>`)
    $(elem).before(`<input class='js-fileExtensions' type='hidden' value=''/>`)

    if (isRequired) {
      $(elem).after(`<input class='js-fileValidation' type='text' required />`)
    }
  })
}
initUpload()

$(document).on('initUploadTrigger', () => {
  initUpload()
})

function generateTemplate(file, currentTarget, formParent) {
  const fileUploadButton = currentTarget.parents('.js-fileUpload').find('.js-fileUploadButton')
  const fileUploadList = currentTarget.parents('.js-fileUpload').find('.js-fileUploadList')

  let tpl = `
    <div class="file-upload-item">
      <div class="file-upload-item__del js-fileUploadInputDel"></div>
      <div class="file-upload-item__name">
        ${file.name}
      </div>
    </div>
  `

  // вариант отображения с прогрессбаром
  if ($(currentTarget).hasClass('js-inputHasProgress')) {
    tpl = `
      <div class="file-upload-item is_loading_progress">
        <div class="file-upload-item__icon"></div>
        <div class="file-upload-item__name">
          <span class="p-relative">
            <span class="name-wrap">
              ${file.name}
            </span>

            <div class="progress-wrap p-abs"><div class="progress"></div></div>
          </span>
          <span class="text-11 c-gray weight-500">${formatBytes(file.size)}</span>
        </div>
        <div class="file-upload-item__del js-fileUploadInputDel"></div>
      </div>
    `
  }

  // formParent.querySelector('.js-fileUploadList').insertAdjacentHTML('beforeend', tpl)
  fileUploadList.find('.file-upload-item:last').append(currentTarget)
  fileUploadList.append(tpl)

  if ($(formParent)[0].checkValidity()) {
    $(formParent).find('button[type="submit"]').attr('disabled', false);
  }

  if ($(currentTarget).hasClass('js-inputHasProgress')) {
    createInputFile(fileUploadButton, true, $(currentTarget).hasClass('js-fileInputHasError'), $(currentTarget).attr('name'), $(currentTarget).hasClass('js-uploadOneFile'))

  } else {
    createInputFile(fileUploadButton, false, $(currentTarget).hasClass('js-fileInputHasError'), $(currentTarget).attr('name'), $(currentTarget).hasClass('js-uploadOneFile'))
  }
}

function createInputFile(parrent, progress = false, errorMsg = false, name = 'files[]', oneFile = false) {
  const inputFile = parrent[0].querySelector('input[data-extensions]');
  const arrFormat = inputFile.dataset.extensions;

  if (progress) {
    parrent.prepend(`
      <input class="${errorMsg ? 'js-fileInputHasError' : ''} ${oneFile ? 'js-uploadOneFile' : ''} js-fileUploadInput js-fileElement js-inputGenerate js-inputHasProgress file-upload-button__input" accept="${arrFormat}" type="file" name="${name}">
    `)
  } else {
    parrent.prepend(`
      <input class="${errorMsg ? 'js-fileInputHasError' : ''} ${oneFile ? 'js-uploadOneFile' : ''} js-fileElement js-fileUploadInput js-inputGenerate file-upload-button__input" accept="${arrFormat}" type="file" name="${name}">
    `)
  }
}

$(document).on('change', '.js-fileUploadInput', (event) => {
  const evTarget = event.target;
  const currentTarget = $(event.currentTarget)
  const fileInput = $(evTarget).parents('.file-upload').find('.js-fileValidation')
  const currentTargetParrent = event.target.closest('form')
  const file = event.target.files[0]
  const currentSize = currentTarget.siblings('.js-fileSize').val()
  const currentExt = currentTarget.siblings('.js-fileExtensions').val()
  const currentExtList = currentExt.replace(/\s+/g, '').split(',')
  const parentFileUpload = currentTarget.parents('.js-fileUpload')
  const validExt = validateExtensions(file, currentExtList)
  const validSize = validateFileSize(file, currentSize)
  const parentForm = evTarget.closest('form')
  const formatFile = `.${evTarget.files[0].name.split('.').slice(-1)[0]}`;

  let checkInput = 0;

  if (evTarget.closest('.js-fileUpload').querySelector('input[data-extensions]').dataset.extensions.split(',').indexOf(formatFile) < 0 && evTarget.closest('.js-fileUpload').querySelector('input[data-extensions]').dataset.extensions !== '') {
    console.log(evTarget)
    if (evTarget.closest('.js-showFormField').querySelector('[data-required]')) {
      $(parentForm).find('button[type="submit"]').attr('disabled', true);
    }
    evTarget.closest('.js-fileUpload').classList.add('is_error');
    return;
  } else {

    if (evTarget.closest('.js-fileUpload').classList.contains('is_error')) {
      evTarget.closest('.js-fileUpload').classList.remove('is_error')
    }

    if (!validSize || !validExt) {
      console.log('Ошибка загрузки файла')

      if (currentTarget.hasClass('js-fileInputHasError')) {
        parentFileUpload.addClass('is_error')
      } else {
        window.openModal('#file-error-modal')
      }

      $(evTarget).val('')
      return
    } else {
      parentFileUpload.removeClass('is_error')
      parentFileUpload.addClass('file_has_value')

      if (fileInput) {
        fileInput.val('е')

        setTimeout(() => {
          fileInput.trigger('change').valid()
        }, 1)
      }
    }

    // если файл только один нужно добавить
    if (currentTarget.hasClass('js-uploadOneFile')) {
      parentFileUpload.addClass('is_one_upload')
    }

    generateTemplate(file, currentTarget, currentTargetParrent)

    setTimeout(() => {
      if ($('.is_loading_progress').length > 0) {
        $('.is_loading_progress').addClass('show_name_file')
      }
    }, 1500)

    if (evTarget.closest('form').querySelector('.js-fileUploadList').children.length) {
      const textArea = evTarget.closest('form').querySelector('textarea.form-input__field')
      let arrRequiredInput = evTarget.closest('form').querySelectorAll('[required]');
      if (evTarget.closest('.js-formOfAddress')) {
        textArea.required = false;
        arrRequiredInput = evTarget.closest('form').querySelectorAll('[required]');

        if (textArea.closest('.is-error')) {
          textArea.closest('.is-error').querySelector('.label-error').remove()
          textArea.closest('.is-error').classList.remove('is-error')
        }
      }

      for (let i = 0; i < arrRequiredInput.length; i++) {
        if (arrRequiredInput[i].getAttribute('type') !== 'file' && arrRequiredInput[i].getAttribute('type') !== 'checkbox') {
          checkInput = 0;
          if (arrRequiredInput[i].value === '') {

            break
          } else {
            checkInput = 1;
          }
        }
      }
    }

  }
})

$(document).on('click', '.js-fileUploadInputDel', (event) => {
  const evTarget = event.target
  const parentForm = evTarget.closest('form')
  const fileInput = $(evTarget).parents('.file-upload').find('.js-fileValidation')

  if (fileInput) {
    fileInput.val('')

    setTimeout(() => {
      fileInput.trigger('change').valid()
    }, 1)
  }

  const arrInputFile = parentForm.querySelectorAll('.js-inputGenerate')
  let counter = 1;
  if (evTarget.closest('.js-formOfAddress')) {
    counter = 0;
  }

  if (counter === 0) {
    if (parentForm.querySelectorAll('.file-upload-item').length === 1) {

      evTarget.closest('form').querySelectorAll('textarea.form-input__field').forEach(el => {
        el.required = true;
      })
    }
  }

  const fileUploadElem = $(event.currentTarget).parents('.js-fileUpload')

  const fileUploadId = fileUploadElem.attr('id')

  evTarget.closest('.file-upload-item').remove()

  if (fileUploadElem.find('.file-upload-item').length < 1) {
    if (fileUploadId) {
      $(`[data-show-id='${fileUploadId}']`).hide()
      $(`[data-hide-id='${fileUploadId}']`).show()
    }

    fileUploadElem.removeClass('file_has_value')
  }

  if (parentForm.querySelector('.js-fileUploadList').children.length === 0) {
    if (counter === 1) {
      arrInputFile.forEach(el => {
        el.required = true;
      })
    }
  }

  fileUploadElem.removeClass('is_one_upload');

  $(".js-fileElement", fileUploadElem).remove();
  $(".js-fileUploadInput", fileUploadElem).val("");
})
