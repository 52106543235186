// акордионы

class Accordion {
  constructor(selector) {
    this.selector = selector
  }

  createAccordion(selector) {
    const accordionList = document.querySelectorAll(this.selector)

    for (const element of accordionList) {
      element.addEventListener('click', function () {
        this.classList.toggle('is-active')
        const panel = this.nextElementSibling
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null
        } else if (panel.closest('.open-accordion')) {
          const parentAccordion = this.closest('[data-accordion-content]')
          parentAccordion.style.maxHeight = `${parentAccordion.scrollHeight + panel.scrollHeight}px`
          panel.style.maxHeight = `${panel.scrollHeight}px`
        } else {
          panel.style.maxHeight = `${panel.scrollHeight}px`
        }
        const parentElem = this.parentNode
        if (this.classList.contains('is-active')) {
          parentElem.classList.add('open-accordion')
        } else {
          parentElem.classList.remove('open-accordion')
        }

        // при открытии текущего у соседних закрываем
        const elemSiblings =  $(parentElem).siblings()
        elemSiblings.removeClass('open-accordion')
        elemSiblings.find('.js-accordionUp').removeClass('is-active')
        elemSiblings.find('.js-accordionDown').removeAttr('style')
        
      })
    }
  }
}

const accordion = new Accordion('.js-accordionUp')

accordion.createAccordion()

// акордионы  - конец
