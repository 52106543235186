import Swiper from 'swiper/swiper-bundle.min'

function initHistorySliders() {
  const sliderTop = new Swiper('.js-historyTop', {
    centeredSlides: true,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    navigation: {
      nextEl: '.js-historyBtnNext',
      prevEl: '.js-historyBtnPrev',
    },
    breakpoints: {
      320: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
    },
  })

  const sliderBottom = new Swiper('.js-historyBottom', {
    slidesPerView: 1,
    spaceBetween: 10,
  })

  sliderTop.controller.control = sliderBottom
  sliderBottom.controller.control = sliderTop
}

$(document).ready(initHistorySliders)
