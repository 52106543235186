const stepWrap = $('.js-stepWrap')
const stepLength = $('.js-selectedStep').length
const currentStepInput = $('.js-currentStep')
const currentStepInputVal = currentStepInput.val()

// находим js-selectedStep добавляем класс active, у соседей убираем
function setSelectedStep(id) {
  $('.js-selectedStep').eq(id).addClass('active').siblings().removeClass('active')
}

function setStepWrapCurrent(currentId) {
  stepWrap.attr('data-current-step', currentId)
  currentStepInput.val(currentId)
  currentStepInput.change()

  if (currentId === stepLength) {
    stepWrap.addClass('last-step')
  } else {
    stepWrap.removeClass('last-step')
  }

  const selectBlock = $('.step-block__select')

  if (selectBlock) {
    selectBlock.find('.current').text(currentId)
    const currentLi = selectBlock.find('li').eq(currentId - 1)

    currentLi.addClass('selected focus').siblings().removeClass('selected focus')
  }
}

$(document).on('change', '.js-selectStep', (event) => {
  const id = Number($(event.currentTarget).val())

  setStepWrapCurrent(id)
})

// выбор шага по пагинации шагов
$(document).on('click', '.js-selectedStep:not(.active)', (event) => {
  const id = $(event.currentTarget).index()

  setSelectedStep(id)
  // делаем плюс 1, потому что index с нуля
  setStepWrapCurrent(id + 1)
})

// выбор по кнопке prev
$(document).on('click', '.js-prevStep', (event) => {
  const currentStepId = Number($('.js-stepWrap').attr('data-current-step'))
  const newStep = currentStepId < 2 ? 1 : currentStepId - 1

  setStepWrapCurrent(newStep)
  // делаем минус 1, потому что index с нуля
  setSelectedStep(newStep - 1)
})

// выбор по кнопке next
$(document).on('click', '.js-nextStep', (event) => {
  const currentStepId = Number($('.js-stepWrap').attr('data-current-step'))
  const newStep = currentStepId < stepLength ? currentStepId + 1 : stepLength

  setStepWrapCurrent(newStep)
  // делаем минус 1, потому что index с нуля
  setSelectedStep(newStep - 1)
})

if ($('.js-currentStep').length > 0) {
  if (currentStepInputVal.length > 0) {
    setStepWrapCurrent(currentStepInputVal)
    // делаем минус 1, потому что index с нуля
    setSelectedStep(currentStepInputVal - 1)
  
    if (+currentStepInputVal === stepLength) {
      stepWrap.addClass('last-step')
    }
  }
}

if ($('.js-selectStep').length > 0) {
  if (currentStepInputVal != 0) {
    $('.js-selectStep').val(currentStepInputVal).change()
  } else {
    $('.js-selectStep').val(1).change()
  }
}

$('.js-saveSubmit').on('click', (event) => {
  const form = $(event.currentTarget).parents('form')

  form.find('[required]').each((index, elem) => {
    $(elem).attr('required', false);
  })
})

$('.js-formSubmit').on('click', (event) => {
  const form = $(event.currentTarget).parents('form')

  form.find('[required]').each((index, elem) => {
    $(elem).attr('required', true);
  })
})