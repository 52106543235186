let minHeightFlag = true

function autosize() {
  const text = $('.js-autoSize');

  text.each(function() {
    $(this).attr('rows',1);
    
    resize($(this));
  });

  text.on('input', function() {
    resize($(this));
  });
  
  function resize(elem) {
    elem.css('height', 'auto');
    elem.css('height', elem[0].scrollHeight+'px');

    if (minHeightFlag) {
      // safari fix bug
      if (elem[0].scrollHeight > 48 && elem[0].scrollHeight < 72) {
        elem.css('min-height', 72+'px');
      } else {
        elem.css('min-height', elem[0].scrollHeight+'px');
      }
    }

    minHeightFlag = false
  }
}

autosize();

$(window).on('resize', () => {
  autosize()
})

$(document).on('initAutosize', () => {
  autosize()
})
