$(document).ready(function() {
  $('body').on('click', '.js-category-tab', function () {
      let key = $(this).data('key');
      if(key === 0) {
          $('.faq-item').css('display', 'block');
          $('.js-category-tab').removeClass('js-tabActive');
          $(this).addClass('js-tabActive');
      } else {
          $('.faq-item').css('display', 'none');
          $('.category-'+key).css('display', 'block');
          $('.js-category-tab').removeClass('js-tabActive');
          $(this).addClass('js-tabActive');
      }
  })
})