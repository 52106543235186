import { mapInit } from './map.js';

// добавление карты только там где она нужна, для оптимизации google page speed
$(window).on('load', () => {
	if ($('#map').length > 0) {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = 'https://api-maps.yandex.ru/2.1/?apikey=e06555b1-2744-4ab7-aebb-64d45d619671&lang=ru_RU';

		document.getElementsByTagName('body')[0].appendChild(script);

		script.onload = function() {
			initYandexMaps();
		}

		function initYandexMaps(){
			if (typeof ymaps !== 'undefined') {
				if ($('#map').length > 0) {
					ymaps.ready(mapInit)
				}
			}
		}
	}
})
