let prevScrollpos = window.pageYOffset
$(window).on('scroll load resize', () => {
  scroll()
})

const getOffsetTopValue = () => {
  if (document.querySelector('.head-banner')) {
    return (document.querySelector('.head-banner').scrollHeight + 50) / 2
  }
  return 75
}

const offsetTopValue = getOffsetTopValue()

function scroll() {
  const currentScrollPos = window.pageYOffset

  if (currentScrollPos > offsetTopValue) {
    $('.header').addClass('has_background')
  } else {
    $('.header').removeClass('has_background')
  }

  if (currentScrollPos > 75) {
    if (prevScrollpos <= currentScrollPos) {
      $('.header, .js-acceleratorPanel').addClass('off')
    } else {
      $('.header, .js-acceleratorPanel').removeClass('off')
    }
    prevScrollpos = currentScrollPos
  } else {
    $('.header, .js-acceleratorPanel').removeClass('off')
  }
}

scroll()

if ($('.js-header-white').length > 0) {
  $('body').addClass('white-header-wrap');
}
