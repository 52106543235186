import svg4everybody from 'svg4everybody'
import $ from 'jquery'

import './vendor/yastatic-share'

svg4everybody()

window.$ = $
window.jQuery = $

import './vendor/jquery-validation'
import './vendor/masked-input'
import './vendor/simplebar'
import './vendor/spincrement'