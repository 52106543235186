let idInput;

$(document).on('change', '.js-showFormField input:not(.js-fileUploadInput)', (event) => {
  inputShowTrigger($(event.currentTarget))
})

$(document).on('change', '.js-showFormField .js-select', (event) => {
  selectShowTrigger($(event.currentTarget))
})

function selectShowTrigger(field) {
  const elem = field
  const id = elem.val()
  const selectListId = elem.attr('id')

  elem.find('option').each((index, item) => {
    if (id !== $(item).val()) {
      hideFieldById($(item).val())
    }
  })

  if (id != 0) {
    showFieldById(id)
    showFieldById(selectListId)
  } else {
    hideFieldById(selectListId)

    elem.find('option').each((index, item) => {
      hideFieldById($(item).val())
    })
  }
}

function inputShowTrigger(field) {
  const elem = field
  const id = elem.attr('id')

  if (elem.attr('type') === 'checkbox' || elem.attr('type') === 'radio') {
    const checkboxList = elem.parents('.js-checkboxList')
    const checkboxListId = checkboxList.attr('id')

    // если хотя бы одно из списка checked показываем нужный блок по id
    if (checkboxList.find('input:checked').length > 0 && checkboxList.find('input:checked').val() !== '0') {
      showFieldById(checkboxListId)
    } else {
      hideFieldById(checkboxListId)
    }
  }

  if (elem.attr('type') === 'checkbox') {
    // для чекбоксов
    if (elem.prop('checked') === true && elem.val() !== 0) {
      showFieldById(elem.val())
    } else {
      hideFieldById(elem.val())
    }
  } else if (elem.attr('type') === 'radio') {
    // для радиокнопок
    const list = elem.parents('.form-checkbox__list')

    list.find('.form-input').each((index, item) => {
      const radioInputVal = $(item).find('input').val()
      
      hideFieldById(radioInputVal)
    })

    const userId = $('meta[name="x-user-id"]').attr('content');
    const pagePathName = `id-${userId ? userId : ''}${window.location.pathname}`;
    if (elem['0'].checked) {
      idInput = elem['0'].value;
    }

    if (JSON.parse(localStorage.getItem(pagePathName))["radio-form"]) {
      idInput = JSON.parse(localStorage.getItem(pagePathName))["radio-form"].value;
    }

    if (elem.val() !== 0) {
      showFieldById(idInput)
    }
  } else {
    // для обычных инпутов, если валидное поле
    if (elem[0].checkValidity() && elem.val().length > 0) {
      showFieldById(id)

    } else {
      hideFieldById(id)
    }
  }
}

function dateRangeShowTrigger(field) {
  const elem = field
  const parentItem = elem.parents('.js-dateRangeId')
  const parentItemId = parentItem.attr('id')
  const dateFrom = parentItem.find('.js-dateInputFrom')
  const dateTo = parentItem.find('.js-dateInputTo')

  if (dateFrom.val().replace(/[^0-9]/g,'').length > 7 && dateTo.val().replace(/[^0-9]/g,'').length > 7) {
    showFieldById(parentItemId)
  } else {
    hideFieldById(parentItemId)
  }
}

$('.js-showFormField .js-select').each((index, field) => {
  selectShowTrigger($(field))
})

$('.js-showFormField input:not(.js-fileUploadInput)').each((index, field) => {
  // console.log($(field))
  inputShowTrigger($(field))
})

function showFieldById(currentId) {
  $(`[data-show-id='${currentId}']`).show()
}

function hideFieldById(currentId) {
  $(`[data-show-id='${currentId}']`).hide()
}

$(document).on('change', '.js-showFormField .js-fileUploadInput', (event) => {
  const elem = $(event.currentTarget)
  const parent = elem.parents('.js-fileUpload')
  const id = parent.attr('id')

  if (parent.find('.file-upload-item')) {
    showFieldById(id)
  } else {
    hideFieldById(id)
  }
})

$(document).on('change', '.js-showFormFieldDateRange input', (event) => {
  dateRangeShowTrigger($(event.currentTarget))
})

$('.js-showFormFieldDateRange input').each((index, field) => {
  dateRangeShowTrigger($(field))
})

// $( document ).ready(() => {
//   document.querySelectorAll('.js-checkboxList').forEach(elem => {
//     if (elem.querySelector('.form-radio input[type="radio"]')) {
//       if (elem.querySelector('.form-radio input[checked]')) {
//         const dataShowIdInput = elem.querySelector('.form-radio input[checked]').getAttribute('value')
//         const arrInput = elem.closest('section').querySelectorAll('[data-show-id]');

//         arrInput.forEach(el => {
//           el.style.display = 'none';
//         })

//         elem.closest('section').querySelector(`[data-show-id="${dataShowIdInput}"]`).style.display = null;
//       }
//     }
//   })
// });