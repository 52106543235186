$(document).on('keydown', '.js-maskPhone', (event) => {
  if (event.which === 32) return false
})

$(document).on('maskInit', () => {
  $('.js-maskPhone').each((index, element) => {
    let maskPlaceholder = '?+7 (999) 999-99-99'
    const _this = $(element)
    if (_this.data('mask')) {
      maskPlaceholder = _this.data('mask')
    }
    _this.mask(maskPlaceholder)
  })

  $('.js-maskDate').each((index, element) => {
    let maskPlaceholder = '?99.99.9999'

    $(element).mask(maskPlaceholder)
  })

  $('.js-maskNumber').each((index, element) => {
    const _this = $(element)
    if (_this.data('mask')) {
      maskPlaceholder = _this.data('mask')
    }
    _this.mask(maskPlaceholder)
  })
})

$(document).trigger('maskInit')

