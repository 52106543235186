if (document.querySelector('.js-playVideo')) {
  document.querySelectorAll('.wrap-video').forEach((elem) => {
    elem.addEventListener('click', (event) => {
      const evTarget = event.target
      if (evTarget.classList.contains('js-playVideo')) {
        evTarget.classList.add('is-hide')
        evTarget.closest('.wrap-video__block').querySelector('video').play()
        evTarget.closest('.wrap-video__block').querySelector('video').controls = true
      }
    })
  })
}
