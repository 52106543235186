const html = $('html')
const modal = $('.js-modalBlock')

function openModal(el) {
  if ((window.screen.width - document.body.clientWidth) > 0) {
    html.addClass('offset-right')
  }
  html.addClass('o-h')
  el.addClass('on')
}

function closeModal(id) {
  const isOnlyOneModal = $('.js-modalBlock.on').length === 1

  // для модалки саппорта
  $('.js-supportButton').removeClass('is_active')

  if (id) {
    $(id).removeClass('on')
    if (isOnlyOneModal) {
      html.removeClass('o-h')
      if (html.hasClass('offset-right')) {
        html.removeClass('offset-right')
      }
    }
    return
  }

  html.removeClass('o-h')
  if (html.hasClass('offset-right')) {
    html.removeClass('offset-right')
  }
  modal.removeClass('on')
}

$(document).on('click', '.js-modal', function (event) {
  const el = $(this).data('modal')

  if (event.target.classList.contains('video-card')) {
    const videoModal = document.querySelector(`#${el}`);
    const pathToVideo = event.target.querySelector('.video-card__wrap').dataset.src;
    videoModal.querySelector('video source').setAttribute('src', pathToVideo);
    videoModal.querySelector('video').load();
  }

  openModal($(`#${el}`))
})

$(document).on('click', '.js-closeModal', (event) => {
  const currentModal = $(event.currentTarget).parents('.js-modalBlock')
  const currentId = currentModal.attr('id')

  event.preventDefault()

  if (currentId === 'modal-video') {
    const videoModal = document.querySelector(`#${currentId}`);
    videoModal.querySelector('video').pause();
    videoModal.querySelector('video source').setAttribute('src', '');
  }

  closeModal(`#${currentId}`)
})

$(document).on('keypress', '.js-modal', function (event) {
  if (event.key === "Enter") {
    const el = $(this).data('modal')
    openModal($(`#${el}`))
  }
})

$(document).on('keypress', '.js-closeModal', (event) => {
  if (event.key === "Enter") {
    const currentModal = $(event.currentTarget).parents('.js-modalBlock')
    const currentId = currentModal.attr('id')

    event.preventDefault()

    closeModal(`#${currentId}`)
  }  
})

window.openModal = function (id) {
  openModal($(`${id}`))
}

window.closeModal = function (id) {
  closeModal(id)
}

$(document).on('triggerSignSuccessModal', () => openModal($('#sign-success')))
