const removeTooltip = () => {
  document.querySelectorAll('.tooltip-content').forEach(elem => {
    if (elem.classList.contains('is_show')) {
      elem.classList.remove('is_show')
    } else if (elem.classList.contains('left')) {
      elem.classList.remove('left')
    } else if (elem.classList.contains('center')) {
      elem.classList.remove('center')
    }
  })
}

$('.js-tooltip').on('click', (event) => {
  const elem = $(event.currentTarget)
  const wrapWidth = elem.parents('.js-tooltipWrap').width()
  const tooltipContent = elem.next()
  const stateDefault = wrapWidth / 3
  const currentPosition = elem.parents('.js-tooltipPosition').position().left

  if (tooltipContent.hasClass('is_show')) {
    $(event.currentTarget).next().removeClass('is_show left center')
  } else {
    removeTooltip();

    // если нужно отобразить tooltip слева
    if (currentPosition < stateDefault) {
      tooltipContent.addClass('left')
    }

    // если нужно отобразить tooltip по центру
    if (currentPosition > stateDefault && currentPosition < stateDefault * 2) {
      tooltipContent.addClass('center')
    }

    // по умолчанию tooltip с правой стороны, и при наведении делаем is_show
    tooltipContent.addClass('is_show')
  }
})

document.addEventListener('click', (event) => {
  const eventTar = event.target;

  if (!eventTar.closest('.js-tooltipPosition')) {
    removeTooltip();
  }
})
