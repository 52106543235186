import Swiper from 'swiper/swiper-bundle.min'

function initSocAdvertising() {
  $('.js-socAdvertising').each((index, elem) => {
    const elemParent = $(elem).parents('.soc-slider')
    $(elem).addClass(`js-socAdvertising-${index}`)

    elemParent.find('.js-socAdvertisingBtnNext').addClass(`js-socAdvertisingBtnNext-${index}`)
    elemParent.find('.js-socAdvertisingBtnPrev').addClass(`js-socAdvertisingBtnPrev-${index}`)

    const sliderAdvert = new Swiper(`.js-socAdvertising-${index}`, {
      navigation: {
        nextEl: `.js-socAdvertisingBtnNext-${index}`,
        prevEl: `.js-socAdvertisingBtnPrev-${index}`,
      },
      breakpoints: {
        320: {
          slidesPerView: 'auto',
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 'auto',
          spaceBetween: 24,
        },
        1023: {
          slidesPerView: 4,
          spaceBetween: 24,
        },
      },
    })
  })
}

$(document).ready(initSocAdvertising)
