import { validateInn, validateOgrn } from "./helpers";

$.extend($.validator.messages, {
  required: 'Поле обязательно для заполнения',
  remote: 'Введите правильное значение',
  email: 'Введите корректную электронную почту',
  url: 'Введите корректный URL',
  date: 'Введите корректную дату',
  dateISO: 'Введите корректную дату в формате ISO',
  number: 'Введите число',
  digits: 'Вводите только цифры',
  letters: 'Вводите только буквы',
  creditcard: 'Введите правильный номер кредитной карты',
  equalTo: 'Пароли не совпадают',
  extension: 'Выберите файл с правильным расширением',
  maxlength: $.validator.format('Максимальная длина описания - {0} символов'),
  minlength: $.validator.format('Введите не меньше {0} символов'),
  rangelength: $.validator.format(
    'Введите значение длиной от {0} до {1} символов',
  ),
  range: $.validator.format('Введите число от {0} до {1}'),
  max: $.validator.format('Максимальная длина описания - {0} символов'),
  min: $.validator.format('Введите число, большее или равное {0}'),
})

function initValidate() {
  $('.js-validate').each((index, form) => {
    $(form).validate({
      ignore: ':hidden',
      errorElement: 'span',
      errorClass: 'label-error',
      onfocusout: function(element) {
        this.element(element); // triggers validation
      },
      onkeyup: function(element, event) {
        return element.classList.contains('js-password') ? this.element(element) : false
      },
      rules : {
        'password_confirmation' : {
          equalTo : '.js-password',
        }
      },
      highlight(element) {
        setTimeout(() => {
          if (!$(element).attr('disabled')) {
            $(element).parent().removeClass('is-success')
            $(element).closest('.form-input').addClass('is-error')

            if ($(element).parent('.jq-checkbox')) {
              const checkboxParent = $(element).parents('label')
              checkboxParent.append(checkboxParent.find('.label-error'))
            }

            if (
              $(element).attr('type') === 'checkbox' ||
              $(element).hasClass('js-select') ||
              $(element).attr('type') === 'radio'
            ) {
              const parent = $(element).parent()

              parent.append(parent.find('.label-error'))
            }
          }
        })

        setTimeout(() => {
          if ($(element).parents('.form-input__wrapper').length > 0) {
            const parentWrap = $(element).parents('.form-input__wrapper')

            parentWrap.find('.label-error').each(function () {
              parentWrap.find('.errors-list').append($(this))
            })
          }
          if ($(element).parents('form').find('.filter__errors').length > 0) {
            const filterForm = $(element).parents('form')
            const filterErrors = filterForm.find('.filter__errors')

            filterForm.find('.filter-dates .label-error').each(function () {
              filterErrors.append($(this))
            })
          }
        }, 0)
      },

      unhighlight(element) {
        setTimeout(() => {
          if (!$(element).attr('disabled')) {
            if ($(element).val() != '')
              $(element).parent().addClass('is-success')
            $(element).closest('.form-input').removeClass('is-error')
          }
        })
      },
    })

    if ($(form).validate().checkForm()) {
      $(form).find('[type="submit"]').prop('disabled', false)
    } else {
      $(form).find('[type="submit"]').prop('disabled', true)
    }

    $('.form-input, .js-agreeCheckbox, .js-fileUploadInput, .js-fileValidation').on('change input', function () {

      if ($(form).validate().checkForm()) {
        $(form).find('[type="submit"]').prop('disabled', false)
      } else {
        $(form).find('[type="submit"]').prop('disabled', true)
      }
    })
  })
}

$.validator.addClassRules("js-fileValidation", {
  required: true,
  // setValid: true
});

// $.validator.addMethod("setValid", function(value, element) {
//   return !!value
// });

$.validator.addMethod('innValid', (value) => {
  const stateValid = validateInn(value)

  return stateValid
}, 'Введите корректный ИНН.')

$.validator.addClassRules('js-innValidate', {
  innValid: true,
})

$.validator.addMethod('ogrnValid', (value) => {
  const stateValid = validateOgrn(value)

  return stateValid
}, 'Введите корректный ОГРН.')

$.validator.addClassRules('js-ogrnValidate', {
  ogrnValid: true,
})

$.validator.addMethod('dateFromValid', (value) => {
  if (value.replace(/[^0-9]/g, '').length < 1) {
    return true
  }

  const dateArray = value.split('.')
  const dateReverse = dateArray.reverse()
  const dateConverted = new Date(dateReverse.join('-'))
  let isValid = false

  // проверка, если дата От валидная
  if (value.length == 10 && dateConverted != 'Invalid Date') {
    const dateTo = $('.js-dateTo').val()
    const dateToArray = dateTo.split('.')
    const dateToReverse = dateToArray.reverse()
    const dateToConverted = new Date(dateToReverse.join('-'))

    if (dateTo.length > 0) {
      // проверка, если дата До валидная
      if (dateTo.length == 10 && dateToConverted != 'Invalid Date') {
        // распарсили даты и сравниваем, если дата От меньше даты До, тогда true
        if (Date.parse(dateConverted) <= Date.parse(dateToConverted)) {
          isValid = true
        }
      }
    } else {
      isValid = true
    }
  }

  return isValid
}, 'Введите корректную дату.')

$.validator.addMethod('dateToValid', (value) => {
  if (value.replace(/[^0-9]/g, '').length < 1) {
    return true
  }

  const dateArray = value.split('.')
  const dateReverse = dateArray.reverse()
  const dateConverted = new Date(dateReverse.join('-'))
  let isValid = false

  // второй метод проверка, если дата До валидная
  if (value.length == 10 && dateConverted != 'Invalid Date') {
    const dateFrom = $('.js-dateFrom').val()
    const dateFromArray = dateFrom.split('.')
    const dateFromReverse = dateFromArray.reverse()
    const dateFromConverted = new Date(dateFromReverse.join('-'))

    // торой метод  проверка, если дата От валидная
    if (dateFrom.length > 0) {
      if (dateFrom.length == 10 && dateFromConverted != 'Invalid Date') {

        // распарсили даты и сравниваем, если дата До больше даты От, тогда true
        if (Date.parse(dateConverted) >= Date.parse(dateFromConverted)) {
          isValid = true
        }
      }
    } else {
      isValid = true
    }
  }

  return isValid
}, 'Введите корректную дату.')

$.validator.addClassRules('js-dateFrom', {
  dateFromValid: true,
})

$.validator.addClassRules('js-dateTo', {
  dateToValid: true,
})

$.validator.addMethod('validateUrl', (value) => {
  // старая регурялка ---- const pattern = new RegExp('^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$');
  const pattern = new RegExp('^((http|https):\/\/)[-a-zA-Zа-яА-Я0-9@:%._\+~#?&//=]{1,256}\\.[a-zа-я]{2,6}$');
  const pattern2 = new RegExp('^((http|https):\/\/)[-a-zA-Zа-яА-Я0-9@:%._\+~#?&//=]{1,256}\\.[a-zа-я]{2,6}/$');
  const pattern3 = new RegExp('^((http|https):\/\/)[-a-zA-Zа-яА-Я0-9@:%._\+~#?&//=]{1,256}\\.[a-zа-я]{2,6}\/[-a-zA-Zа-яА-Я0-9@:%._\+~#?&//=]{1,256}$');

  return !!pattern.test(value) || !!pattern2.test(value) || !!pattern3.test(value) || value == '';
}, 'Введите корректный URL.')

$.validator.addClassRules('js-validateUrl', {
  validateUrl: true,
})

$.validator.addMethod('validateSocUrl', (value) => {
  const patternSoc = new RegExp('^((http|https):\/\/)[-a-zA-Zа-яА-Я0-9@:%._\+~#?&//=]{1,256}\\.[a-zа-я]{2,6}\\b([-a-zA-Zа-яА-Я0-9@:%._\+~#?&//=]*)$');

  return !!patternSoc.test(value) || value == '';
}, 'Введите корректный URL.')

$.validator.addClassRules('js-validateSocUrl', {
  validateSocUrl: true,
})

$.validator.addMethod('validatePassword', (value) => {
  return value.indexOf(' ') < 0
}, 'Введите пароль без пробелов')

$.validator.addClassRules('js-password', {
  validatePassword: true,
  required: true,
})

$(document).on('initValidateTrigger', () => {
  initValidate()
})

$(document).trigger('initValidateTrigger')

$(document).on('change input blur', '.form-input', (event) => {
  const _this = $(event.currentTarget)
  if (_this.val() === '') _this.parent().removeClass('is-success')
})

$(document).on('change input blur', '.form-textarea textarea', (event) => {
  const _this = $(event.currentTarget)
  _this.valid()
})

$('.js-validate .js-select').on('change', (event) => {
  $(event.currentTarget).valid()
})

$(document).on('input', '.js-letters', (event) => {
  if (event.currentTarget.value.match(/[^а-яА-Яa-zA-Z-\s]/g)) {
    event.currentTarget.value = event.currentTarget.value.replace(/[^а-яА-Яa-zA-Z-\s]/g, '')
  }
})

$(document).on('input', '.js-numbers', (event) => {
  if (event.currentTarget.value.match(/[^0-9]/g)) {
    event.currentTarget.value = event.currentTarget.value.replace(/[^0-9]/g, '')
  }
})

$('.js-maskPhone').each((index, elem) => {
  const isRequired = $(elem).attr('required')

  if (isRequired) {
    $(elem).removeClass('js-phoneValidate')
    $(elem).addClass('js-phoneValidateRequired')
  }
})

/*
* Валидация телефона
*/
$.validator.addMethod('phonecustom', function(value) {
  if(value == '+7 (___) ___-__-__') return true;

  return value.match(/^\+7\s\([0-9]{3}\)\s[0-9]{3}\-[0-9]{2}\-[0-9]{2}$/);
}, 'Указан неверный формат телефона.');

$.validator.addClassRules('js-phoneValidate', {
  phonecustom: true,
});

// если есть атрибут required
$.validator.addMethod('phonecustomRequired', function(value) {
  return value.match(/^\+7\s\([0-9]{3}\)\s[0-9]{3}\-[0-9]{2}\-[0-9]{2}$/);
}, 'Указан неверный формат телефона.');

$.validator.addClassRules('js-phoneValidateRequired', {
  phonecustomRequired: true,
});

$(document).on('change', '.js-validateUrl', (event) => {
  const elem = $(event.currentTarget)
  const value = elem.val()

  if (value.indexOf('http') == -1) {
    if (value.indexOf('://') == -1) {
      elem.val(`https://${value}`)
    }
  }
})
