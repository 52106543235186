export function mapInit() {
  if (document.querySelector('#map')) {
    /*Яндекс карта*/
    if (document.querySelector('#map')) {
      /*Яндекс карта*/
      ymaps.ready(function () {
        var myMap = new ymaps.Map(
            'map',
            {
              center: [55.766131, 37.604205],
              zoom: 15,
              controls: ['zoomControl'],
            },
            {
              searchControlProvider: 'yandex#search',
            },
          ),
          myPlacemark = new ymaps.Placemark(
            myMap.getCenter(),
            {
              hintContent: 'ИРИ',
              balloonContent: '',
            },
            {
              // Опции.
              // Необходимо указать данный тип макета.
              iconLayout: 'default#image',
              // Своё изображение иконки метки.
              iconImageHref: document.querySelector('#map').dataset.urlIcon,
              // Размеры метки.
              iconImageSize: [44, 56],
              // Смещение левого верхнего угла иконки относительно
              // её "ножки" (точки привязки).
              iconImageOffset: [0, -40],
            },
          )
    
        myMap.geoObjects.add(myPlacemark)
      })
    }
  }    
}
