$(document).on('input', '.msg-form textarea', (event) => {
  const elem = $(event.currentTarget)
  const parent = elem.parents('.msg-form')

  if (elem.val().length > 0) {
    parent.addClass('has_value')
  } else {
    parent.removeClass('has_value')
  }
})

$(document).ready(function() {
  $('.msg-form textarea')
    .focus((event) => {
      const elem = $(event.currentTarget)
      const parent = elem.parents('.msg-form')

      parent.addClass('is_focus')
    })

    .focusout((event) => {
      const elem = $(event.currentTarget)
      const parent = elem.parents('.msg-form')

      parent.removeClass('is_focus')
    })
})

if ($('.msg-form').length > 0) {
  window.scrollTo({
    top: $('.msg-form').offset().top,
    behavior: "smooth"
  })
}
