export const validateExtensions = (file, extensions) => {
  const { name } = file
  let valid = false
  for (let j = 0; j < extensions.length; j += 1) {
    const extension = extensions[j]
    if (
      name
        .substr(name.length - extension.length, extension.length)
        .toLowerCase() === extension.toLowerCase()
    ) {
      valid = true
      break
    }
  }
  return valid
}

export const validateFileSize = (file, maxSize) => file.size <= maxSize

export function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) {
		return '0';
	} else {
		var k = 1024;
		var dm = decimals < 0 ? 0 : decimals;
		var sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
		var i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}
}

export const validateInn = function (inn) {
  if (typeof inn === "number") {
    inn = inn.toString()
  } else if (typeof inn !== "string") {
    inn = ""
  }

  if (inn.length && inn.length <= 12) {
    return true
  }else{
    return false
  }
}

export const validateOgrn = function (chekedValue) {
  if (typeof chekedValue === "number") {
    chekedValue = chekedValue.toString()
  } else if (typeof chekedValue !== "string") {
    chekedValue = ""
  }

  if (chekedValue.length && chekedValue.length <= 15) {
    return true
  }else{
    return false
  }
}

$('.js-ogrnValidate').on('input', function() {
  this.value = this.value
    .replace(/[^\d]/g, '');// numbers and decimals only

});

$('.js-innValidate').on('input', function() {
  this.value = this.value
    .replace(/[^\d]/g, '');// numbers and decimals only

});