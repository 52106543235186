import './vendor'

import './components/history-slider'
import './components/soc-slider'
import './components/org-slider'
import './components/fixed-header'
import './components/file-upload'
import './components/validate'
import './components/accordion'
import './components/yandex-map'
import './components/news-detail-slider'
import './components/play-video'
import './components/passwordInput'
import './components/menu'
import './components/textareaAutoSize'
import './components/modal'
import './components/tooltip'
import './components/formAddSocials'
import './components/datepicker'
import './components/mask'
import './components/simplebar'
import './components/form-steps-localstorage'
import './components/form-steps'
import './components/select'
import './components/form-steps-field'
import './components/form-steps-field-hide'
import './components/msg'
import './components/toggle-mobile'
import './components/checkTheProfile'
import './components/table-year-total'
import './components/hidden-list'
import './components/number-reveal'
import './components/toTop'
import './components/filter-faq'
import './components/closeAlert'
