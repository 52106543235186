const html = $('html')
const body = $('body')

$('.js-menu').on('click', () => {
  // if ((window.screen.width - document.body.clientWidth) > 0) {
  //   html.toggleClass('offset-right')
  // }

  html.toggleClass('o-h')  
  body.toggleClass('menu-on')
})

$('.js-list').on('click', function () {
  $(this).addClass('active')
})

$('.js-parent').on('click', function (e) {
  e.stopPropagation()
  $(this).closest('.parent').removeClass('active')
})

$('.js-sublist > a, .js-sublist > span').on('click', function (event) {
  event.preventDefault();
  $(this).closest('.js-sublist').toggleClass('active').find('.header-sublist').toggleClass('active').slideToggle(300)
})

// // высчет высоты адаптива с вкладками
// let vh = window.innerHeight * 0.01
// document.documentElement.style.setProperty('--vh', `${vh}px`)

// window.addEventListener('resize', () => {
//   vh = window.innerHeight * 0.01
//   document.documentElement.style.setProperty('--vh', `${vh}px`)
// })