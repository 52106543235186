const inputSocialList = $('.js-inputSocialList')
const inputSocialName = $('.js-inputSocial input').attr('name')
const inputSocialPlaceholder = $('.js-inputSocial input').attr('placeholder')

$('.js-addSocial').on('click', (event) => {
  const inputSocialLength = $('.js-inputSocial').length

  createSocialInput(inputSocialName, inputSocialPlaceholder)

  if (inputSocialLength > 7) {
    $(event.currentTarget).hide()
  }
})

$(document).on('click', '.js-socialDelete', (event) => {
  $(event.currentTarget).parent().remove()

  $('.js-addSocial').show()
})

function createSocialInput(inputName, placeholder) {
  const tpl = `
    <div class="form-input__social js-inputSocial">
      <input class="form-input__field js-validateSocUrl" type="text" name="${inputName}" placeholder="${placeholder}">
      <a class="form-input__social-delete d-f ai-c js-socialDelete" href="javascript:;"></a>
    </div>`

  inputSocialList.append(tpl)
}
