$(document).ready(function () {
  var show = true;
  var countbox = ".js-count-wrapper";
  if($(countbox)[0]){
    $(window).on("scroll load resize", function () {

      if (!show) return false;

      var windowHeight = window.innerHeight;
      var revealPoint = 150;
      var revealTop = $(countbox)[0].getBoundingClientRect().top;

      if (revealTop < windowHeight - revealPoint) {
        $('.js-count-number').css('opacity', '1');
        $('.js-count-number').spincrement({
          thousandSeparator: "",
          duration: 1200
        });

        show = false;
      }
    });
  }

});