let isAnimated = false

for(let i of document.getElementsByClassName('js-change-hidden-list')){
  i.addEventListener('click', (e) => {
    if(!isAnimated) {
      const targetId = e.target.getAttribute('data-target');
      const list = document.querySelectorAll(`#${targetId}`)[0];
      const content = document.querySelectorAll(`#${targetId} .js-hidden-list-content`)[0];
      const isHidden = list.classList.contains('hidden-list--hidden');
      content.setAttribute('style', `height: ${content.scrollHeight}px`);

      setTimeout(() => {
        if (isHidden) {
          list.classList.remove('hidden-list--hidden');
        } else {
          list.classList.add('hidden-list--hidden');
        }

        isAnimated = true;
        setTimeout(() => {
          isAnimated = false;
          content.setAttribute('style', ``);
        }, 500);
      })
    }
  })
}
