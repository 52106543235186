$('.application-topic__table table').each((index, elem) => {
  const table = $(elem)
  const itemsTr = table.find('tr:not(:first-child)')
  const lastTr = table.find('tr:last-child')
  const th = table.find('th:not(:first-child):not(:nth-child(2))')

  if (lastTr.text().toLowerCase().indexOf('итого') != -1) {
    lastTr.addClass('total')
  }

  th.each((indexTh, elemTh) => {
    const title = $(elemTh).text()

    itemsTr.each((index, itemTr) => {
      $(itemTr).find('td').eq(indexTh + 2).prepend(`<span>${title}</span>`)
    })
  })
})
