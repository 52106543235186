
const userId = $('meta[name="x-user-id"]').attr('content')
const pagePathName = `id-${userId ? userId : ''}${window.location.pathname}`

let form = {}

$('.js-currentStep').on('change', (event) => {
  form.currentStep = $(event.currentTarget).val()
})

if ($('.js-stepWrap').length > 0) {
  if (localStorage.getItem(pagePathName)) {
    form = JSON.parse(localStorage.getItem(pagePathName))

    for (let key in form) {
      // key ключ, form[key] значение
      if (key !== 'currentStep') {
        addValueField(key, form[key].value, form[key].checked)
      } else {
        $('.js-currentStep').val(Number(form[key] === 0 ? 1 : form[key]))
      }
    }
  }
}

$(document).on('change', '.js-stepWrap input:not(.js-fileUploadInput)', (event) => {
  const elem = $(event.currentTarget)
  const nameInput = elem.attr('name')

  const tmp = {
    value: elem.val(),
    checked: elem.prop('checked') === true ? elem.data('index') : null
  }

  if (elem.attr('type') === 'checkbox') {
    const convertName = `${nameInput}__${elem.data('index')}`

    form[convertName] = tmp
    setFormByStorage(form)
  } else {
    if (!elem.hasClass('js-dateFrom') && !elem.hasClass('js-dateTo')) {
      form[nameInput] = tmp
      setFormByStorage(form)
    }

    if (elem.hasClass('js-dateFrom')) {
      const nameDateFrom = `${nameInput}__from`

      form[nameDateFrom] = tmp
      setFormByStorage(form)
    }

    if (elem.hasClass('js-dateTo')) {
      const nameDateTo = `${nameInput}__to`

      form[nameDateTo] = tmp
      setFormByStorage(form)
    }
  }
})

$(document).on('change', '.js-stepWrap .js-select', (event) => {
  const elem = $(event.currentTarget)
  const nameInput = elem.attr('name')

  const tmp = {
    value: elem.val(),
    checked: null,
  }

  if (tmp.value !== null) {
    form[nameInput] = tmp
    setFormByStorage(form)
  }
})

function setFormByStorage(formObject) {
  localStorage.setItem(pagePathName, JSON.stringify(formObject))
}

function addValueField(name, value, checked) {
  if (checked !== null) {
    const nameSplit= name.split('__')
    const currentName = nameSplit[0]

    $(`[name='${currentName}'][data-index='${checked}']`).prop('checked', true)
  } else {
    if (name.indexOf('__') !== -1) {
      const nameSplit= name.split('__')
      const currentName = nameSplit[0]
      name = currentName

      if ($(`.js-dateFrom[name='${name}']`) && nameSplit[1] == 'from') {
        const dateFrom = $(`.js-dateFrom[name='${name}']`)

        if (dateFrom.val() == '') {
          dateFrom.val(value)
        }
      }

      if ($(`.js-dateTo[name='${name}']`) && nameSplit[1] == 'to') {
        const dateTo = $(`.js-dateTo[name='${name}']`)

        if (dateTo.val() == '') {
          dateTo.val(value)
        }
      }

    } else {
      const elemByName = $(`[name='${name}']`)

      // самые обычные инпуты
      if (elemByName.val() == '') {
        elemByName.val(value)
      }

      if (elemByName.is('select')) {
        elemByName.find(`option[value="${value}"]`).attr('selected', 'true')
      } 
    }
  }
}
