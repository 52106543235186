const btnPass = document.querySelectorAll('.js-btnPassword');

btnPass.forEach(elem => {
  elem.addEventListener('click', event => {
    const evTarget = event.target;
    const targetBtn = evTarget.getAttribute('data-target');
    const inputPass = document.querySelector(targetBtn);

    console.log()

    if (inputPass.getAttribute('type') === 'password') {
      inputPass.setAttribute('type', 'text');
      evTarget.classList.add('is-active')
    } else {
      inputPass.setAttribute('type', 'password');
      evTarget.classList.remove('is-active')
    }
  })
})