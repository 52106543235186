// import dragscroll from 'dragscroll'

$('.js-bar').each(function (index, el) {
  new SimpleBar(el)
})

// $('.simplebar-content-wrapper').addClass('dragscroll')
// dragscroll.reset()

$(document).ready(() => {
  $('.nice-select .list').each(function (index, el) {
    new SimpleBar(el)
  })
})
