import flatpickr from 'flatpickr'
import { Russian } from 'flatpickr/dist/l10n/ru.js'

const inputDate = $('.js-date').flatpickr({
  dateFormat: 'd.m.Y',
  locale: Russian,
  disableMobile: 'true',
  // maxDate: 'today',
  onChange: function() {
    $('.js-date').focusout()
  },
  onOpen: function() {
    if ($('.flatpickr-calendar .js-today').length < 1) {
      $('.flatpickr-calendar').append('<div class="flatpickr-bottom"><div class="js-today pointer d-ib c-green-dark">Сегодня</div></div>');
    }
  }
})

$('.js-date').each((index, elem) => {
  const parentItem = $(elem).parent().parent()

  if (parentItem.hasClass('js-tooltipWrap')) {
    parentItem.addClass('position_start')
  }
})

$(document).on('click', '.js-today', () => {
  inputDate.setDate(new Date())
  inputDate.close()
  $('.js-date').focusout()
})

if (document.querySelector('.js-dateRangeId')) {
  function convertDateFormat(dateString) {
  
    // console.log(dateString);
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // месяцы считаются с 0, поэтому добавляем 1
    const year = date.getFullYear();
    // console.log(`${day}.${month}.${year}`)
    return `${day}.${month}.${year}`;
  }

  function changeActiveDate(obj) {
    const maxDays = new Date(obj.currentYear, obj.currentMonth + 1, 0).getDate();

    obj.days.childNodes.forEach(elem => {
      if (Number(elem.innerText) === maxDays - (maxDays - 1) || Number(elem.innerText) === maxDays) {
        elem.classList.add('action-day')
        // console.log(elem.classList);
      }

      if (Number(obj.currentMonth) === new Date(document.querySelector('.js-dateTo').dataset.dateMax).getMonth() && Number(elem.innerText) === new Date(document.querySelector('.js-dateTo').dataset.dateMax).getDate()) {
        elem.classList.add('action-day')
      }
    })

    
  }

  $('.js-dateRange').each((index, elem) => {
    const field = $(elem)
    const parentField = field.parents('.d-f.d-m-b')
    const dateInputFrom = parentField.find('.js-dateInputFrom')
    const dateInputTo = parentField.find('.js-dateInputTo')
    const showDateRange = parentField.find('.js-showDateRange')
  
    const inputDateRange = field.flatpickr({
      mode: 'range',
      dateFormat: 'd.m.Y',
      locale: Russian,
      minDate: convertDateFormat(elem.closest('.js-dateRangeId').querySelector('.js-dateFrom').dataset.dateMin),
      maxDate: convertDateFormat(elem.closest('.js-dateRangeId').querySelector('.js-dateTo').dataset.dateMax),
      // enable: [
      //     function(date) {
      //         // disable every multiple of 8
      //         // console.log(new Date(date.getFullYear(), date.getMonth(), 0).getDate())
      //         return (date.getDate() === 1);
      //     }
      // ],
      
      onReady: function(dObj, dStr, fp, dayElem) {
        changeActiveDate(fp)
        // console.log(new Date(fp.currentYear, fp.currentMonth + 1, 0).getDate())
      },

      onMonthChange: function (selectedDates, dateStr, instance) {
        changeActiveDate(instance)
      },
    
      onChange: function (selectedDates, dateStr, instance) {
        changeActiveDate(instance)
        if (dateInputFrom.length > 0 && dateInputTo.length > 0) {
          const dateArray = dateStr.split(' ')
          const dateFrom = dateArray[0]
          const dateTo = dateArray[2]
    
          dateInputFrom.val(dateFrom)
          dateInputTo.val(dateTo)
    
          dateInputFrom.change()
          dateInputTo.change()
    
          if (selectedDates.length > 1 && dateArray.length < 2) {
            dateInputTo.val(dateFrom)
          }
    
          dateInputFrom.parent().removeClass('is-error')
          dateInputTo.parent().removeClass('is-error')
        }
      },
      onOpen: function(selectedDates, dateStr, instance) {        
        setTimeout(() => {
          changeActiveDate(instance)
        }, "1");

        parentField.addClass('datepicker_open')
      },
      onClose: function() {
        parentField.removeClass('datepicker_open')
      },
    })
  
    showDateRange.on('click', () => {
      inputDateRange.open()
    
      let currentDate = null
    
      // если dateInputFrom не пустое и валидное
      if (dateInputFrom.val().length > 0 && !dateInputFrom.parent().hasClass('is-error')) {
        currentDate = dateInputFrom.val()
      }
    
      // если dateInputTo не пустое и валидное и при этом currentDate не null
      if (dateInputTo.val().length > 0 && !dateInputTo.parent().hasClass('is-error') && currentDate) {
        currentDate = `${dateInputFrom.val()} — ${dateInputTo.val()}`
      }
    
      inputDateRange.setDate(currentDate)
    })
  })
}